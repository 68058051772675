import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO,  PACIENTE,CITA, CAT_DIA_SEMANA} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_DIA_SEMANAES } from "../constants/DiaSemana-action-type";


/* Funcion para loggin llamada logMiddleware */
const createCatDiaSemanaRestMiddleware =(catTipoCitaURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_DIA_SEMANA]: new RestDataSource(catTipoCitaURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_DIA_SEMANAES:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].GetData((dataCatDiaSemana,mensaje)=>{ 
                                if(dataCatDiaSemana===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_DIA_SEMANAES,
                                    dataType:action.dataType,
                                    payload:dataCatDiaSemana}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatDiaSemana.length});
                                    }
                                }
                            });   
                break;   

 

 

        default:
                next(action);

    }
}

}

export default createCatDiaSemanaRestMiddleware;