import {CONSULTAR_PACIENTEES,AGREGAR_PACIENTE,ACTUALIZAR_PACIENTE, ELIMINAR_PACIENTE} from "../constants/Paciente-action-type";


/* PACIENTEES (HOSPITAL CLINICA) */
//(21Feb21 Dom. Se agregag nuevo action para consultar los pacientes por la institucion pasada como parametro)
export const obtenerPacienteListXInstitucionWSAction = (dataType,institucion)=> {
    return {
        type:CONSULTAR_PACIENTEES,
        dataType:dataType,
        payload:institucion,
        loading: false
    }
}

export const obtenerPacienteListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_PACIENTEES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarPacienteWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PACIENTE,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarPacienteWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PACIENTE,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarPacienteWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PACIENTE,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
