import {PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
                    INICIA_CREACION_PACIENTE,
                        FINALIZA_CREACION_PACIENTE,
                            AUTENTICACION_LOGIN,INICIA_LOGIN,FINALIZA_LOGIN,LOGIN_EXITO,
                                MOSTRAR_MENSAJE, MOSTRAR_MENSAJE_TUTORIAL, TIPO_DE_DATO, MOSTRAR_BUSQUEDA, MODO_VISTA} from "../constants/action-type";



/*  */
export const solicitandoPeticionWS=()=>({
    type:PETICION_WS_SOLICITANDO
})

export const actualizarRegistrosAction=(dataType,registros)=>({
    type:TIPO_DE_DATO,
    registros:registros,
    dataType:dataType
})

export const exitosaPeticionWS=(dataType,registros)=>({
    type:PETICION_WS_EXITOSA,
    registros:registros,
    dataType:dataType
})


export const errorPeticionWS=()=>({
    type:PETICION_WS_ERROR
})


export const iniciaCreacionPaciente=()=>({
    type:INICIA_CREACION_PACIENTE
})

export const finalizaCreacionPaciente=()=>({
    type:FINALIZA_CREACION_PACIENTE
})
/* login */
export const autenticacionLogin=(payload)=>({
    type:AUTENTICACION_LOGIN,
    payload:payload
})

export const iniciaLogin=()=>({
    type:INICIA_LOGIN
})

export const finalizaLogin=()=>({
    type:FINALIZA_LOGIN
})

export const exitoLogin=()=>({
    type:LOGIN_EXITO
})

/* Mensaje Notificaion */

export const mostrarMensaje=(abrirMensaje,mensaje)=>({
    type:MOSTRAR_MENSAJE,
    mensaje:mensaje,
    abrirMensaje:abrirMensaje
})

/* Mensaje Notificaion Tutorial*/

export const mostrarMensajeTutorial=(abrirMensaje,mensaje)=>({
    type:MOSTRAR_MENSAJE_TUTORIAL,
    mensaje:mensaje,
    abrirMensaje:abrirMensaje
})

//(05nov22) Se agrega estado para mostrar busqueda

export const mostrarBusqueda=(mostrarBusqueda)=>({
    type:MOSTRAR_BUSQUEDA,
    mostrarBusqueda:mostrarBusqueda
})

//(08dic22) Se agrega action para cambiar el tipo de MODO_VISTA del calendario
export const setModoVista=(modoVista)=>({
    type:MODO_VISTA,
    modoVista:modoVista
})

