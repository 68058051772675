import {CONSULTAR_MEDICO_USUARIOS_LOCAL_STORAGE,CONSULTAR_MEDICO_USUARIOS,CONSULTAR_MEDICO_USUARIOS_BY_ID, AGREGAR_MEDICO_USUARIO, ACTUALIZAR_MEDICO_USUARIO,ELIMINAR_MEDICO_USUARIO} from "../constants/MedicoUsuario-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (MEDICO_USUARIOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_MEDICO_USUARIOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
        case CONSULTAR_MEDICO_USUARIOS_BY_ID:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
        case CONSULTAR_MEDICO_USUARIOS_LOCAL_STORAGE:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }            
            
        case AGREGAR_MEDICO_USUARIO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_MEDICO_USUARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_MEDICO_USUARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER MEDICO_USUARIOS");
            return state||estadoInicial.datos;
        

    }
}
