import {CONSULTAR_CAT_ESTADO_CITAES,AGREGAR_CAT_ESTADO_CITA,ACTUALIZAR_CAT_ESTADO_CITA, ELIMINAR_CAT_ESTADO_CITA} from "../constants/CatEstadoCita-action-type";


/* CAT_ESTADO_CITAES (HOSPITAL CLINICA) */
export const obtenerCatEstadoCitaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_CAT_ESTADO_CITAES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarCatEstadoCitaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_ESTADO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatEstadoCitaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_ESTADO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatEstadoCitaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_ESTADO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
