import {MOSTRAR_BUSQUEDA, TIPO_DE_DATO,MODO_VISTA} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.estados,action){

    console.log("ESTADO (PETICION EXITOSA) "+action.dataType);
    switch(action.type){
            
        case TIPO_DE_DATO:
            return {
                ...state,
                selectedType:action.dataType,
                registros:action.registros
            }   
                    
        case MOSTRAR_BUSQUEDA:

            return {
                ...state,
                mostrarBusqueda:action.mostrarBusqueda
            }  
        case MODO_VISTA:

            return {
                ...state,
                modoVista:action.modoVista
            }  
                
        default:
            return state||estadoInicial.estados;
    }

}