import {RestDataSource} from "./RestDataSource";
import { PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,CAT_SEXO} from "../constants/action-type";
import { CONSULTAR_SEXO } from "../constants/CatSexo-action-type";


/* Funcion para loggin llamada logMiddleware */
const createCatSexoRestMiddleware =(sexoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_SEXO]: new RestDataSource(sexoURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CAT_SEXO INICIO */                                    
                case CONSULTAR_SEXO:
                    if(action.loading){
                        next({type:PETICION_WS_SOLICITANDO});
                    }
                    dataSources[action.dataType].GetData((dataSexo,mensaje)=>{ 
                        if(dataSexo===null){
                                next({type:PETICION_WS_ERROR});
                        }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_SEXO,
                                    dataType:action.dataType,
                                    payload:dataSexo
                                    }); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});                                    
                                }
                        }
                    });   
                break;   


        default:
                next(action);

    }
}

}

export default createCatSexoRestMiddleware;