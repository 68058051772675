import {CONSULTAR_MOVIMIENTOS, AGREGAR_MOVIMIENTO, ACTUALIZAR_MOVIMIENTO,ELIMINAR_MOVIMIENTO} from "../constants/Movimiento-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (MOVIMIENTOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_MOVIMIENTOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_MOVIMIENTO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_MOVIMIENTO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_MOVIMIENTO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER MOVIMIENTOS");
            return state||estadoInicial.datos;
        

    }
}
