import {RestDataSource} from "./RestDataSource";
import {CONSULTAR_USUARIOS,AGREGAR_USUARIO,ACTUALIZAR_USUARIO, ELIMINAR_USUARIO} from "../constants/Usuario-action-type";
import {CONSULTAR_MEDICO_USUARIOS} from "../constants/MedicoUsuario-action-type";
import {ID_ROL_MEDICO} from "../../utils/constantes"

import {USUARIO,USUARIO_ROL,
        MOSTRAR_MENSAJE,
        PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
        TIPO_DE_DATO,
        ESTADO,MEDICO_USUARIO
        } from "../constants/action-type";

/* middleware pasa como parameto 2 Url para realizar la consulta antes de enviar la llamada a los reducers
y regresa una funcion
*/

export const createMedicoUsuarioMiddleware =(medicoUsuarioURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [MEDICO_USUARIO]: new RestDataSource(medicoUsuarioURL),
    }

/* Realiza el dispacht dependiendo de la accion  */
    return ({dispath,getState})=>next=>action =>{

        console.log("Dentro de createMedicoUsuarioMiddleware:"+action.type);

        /* Antes de enviar al Reducer */
        switch(action.type){

  /** USUARIO INICIO */
                case CONSULTAR_MEDICO_USUARIOS:  /*APARTIR DEL ID DEL ID DEL USUARIO OBTENERT ID MEDICO*/
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[action.dataType].GetData((data,mensaje)=>{ 
                        if(data===null){
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:CONSULTAR_MEDICO_USUARIOS,
                            dataType:action.dataType,
                            payload:data}); 
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:data.length});
                            }
                        }
                        }
                    ); 

                break;   
 
               


            default:
                    next(action);

        }
        

    }

}