import {CONSULTAR_CITAES,AGREGAR_CITA,ACTUALIZAR_CITA, ELIMINAR_CITA} from "../constants/Cita-action-type";


/* CITAES (HOSPITAL CLINICA) */
export const obtenerCitaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_CITAES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarCitaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCitaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCitaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
