import {STATE_START_EDITING,
        STATE_END_EDITING,INICIANDO_CRECION_PACIENTE, STATE_START_CREATING, INICIA_CREACION_PACIENTE,
            FINALIZA_CREACION_PACIENTE,
            MOSTRAR_MENSAJE,
            QUITAR_MENSAJE} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.estadosMensaje,action){

    console.log("ESTADO (PETICION EXITOSA) "+action.dataType);
    switch(action.type){
           
        case INICIA_CREACION_PACIENTE:
            return {
                ...state,
                openModalPaciente:true
            }  

        case FINALIZA_CREACION_PACIENTE:
            return {
                ...state,
                openModalPaciente:false
            }  

        case MOSTRAR_MENSAJE:
            return {
                ...state,
                mensaje:action.mensaje,
                abrirMensaje:action.abrirMensaje
            }  

        default:
            return state||estadoInicial.estadosMensaje;
    }

}