
export const INSTITUCION="instituciones";
export const INSTITUCION_PACIENTE="institucionesPaciente";
export const INSTITUCION_MEDICO="institucionMedico";
export const USUARIO="usuarios";
export const USUARIO_ROL="usuariosRol";
export const ESTADO="estado";
export const MEDICO="medicos";
export const PACIENTE="pacientes";
export const CALENDARIO="calendario";
export const CITA="agendados";
export const RECORDATORIO="recordatorio";
export const ESQUEMA_TRABAJO="Plan de Trabajo";
export const COLOR="color";
export const ESQUEMA_TIPO_CITA="esquemaTipoCita";
export const PERIODO="periodo";
export const HORARIO="horario";
export const ESQUEMA_TIPO_CITA_HORARIO="esquemaTipoCitaHorario";
export const MEDICO_USUARIO="medicoUsuario";
export const PACIENTE_USUARIO="pacienteUsuario";
export const GLUCOSA="glucosa";
export const PRESION_ARTERIAL="presionArterial";
export const COMIDA="comida";
export const PAGINA_WEB="paginaWeb";
export const PRODUCTO="producto";
export const PROCEDIMIENTO="procedimiento";
export const MOVIMIENTO="movimiento";
export const TECNICO="tecnico";

export const PAGINA_WEB_IMAGEN="paginaWebImagen";

export const CAT_ROL="cat_rol";
export const CAT_ESTADO_CITA="cat_estado_cita";
export const CAT_TIPO_CITA="Tipos de Citas";
export const CAT_DIA_SEMANA="cat_dia_semana";
export const CAT_SEXO="cat_sexo";
export const CAT_UNIDAD_MEDIDA="cat_unidad_medida";
export const CAT_TIPO_PRODUCTO="cat_tipo_producto";
export const CAT_TIPO_COMIDA="cat_tipo_comida";
export const CAT_TIPO_MOVIMIENTO="cat_tipo_movimiento";
export const CAT_TIPO_PROCEDIMIENTO="cat_tipo_procedimiento";



export const STATE_START_EDITING="state_start_editing";
export const STATE_END_EDITING="state_end_editing";
export const STATE_START_CREATING="state_start_creating";

/* model action type */
export const CONSULTAR ="CONSULTAR";
export const UPDATE ="UPDATE";
export const DELETE ="DELETE";

/* model action type */
export const GET_DATOS_WS ="GET_PRODUCTO_WS";
export const GET_PACIENTES_WS ="GET_PACIENTES_WS";

/* loading PETICION WS */
export const PETICION_WS_SOLICITANDO ="SOLICITANDO_PETICION_WS";
export const PETICION_WS_EXITOSA ="EXITOSA_PETICION_WS";
export const PETICION_WS_ERROR ="ERROR_PETICION_WS";

export const INICIA_CREACION_PACIENTE="INICIA_CREACION_PACIENTE";
export const FINALIZA_CREACION_PACIENTE="FINALIZA_CREACION_PACIENTE";

/* LOGIN */

export const INICIA_LOGIN="INICIA_LOGIN";
export const AUTENTICACION_LOGIN="AUTENTICACION_LOGIN";
export const FINALIZA_LOGIN="FINALIZA_LOGIN";
export const LOGIN_FALLO="LOGIN_FALLO";
export const LOGIN_EXITO="LOGIN_EXITO";

/* AUTENTICACION */

export const USUARIO_ACTUAL="USUARIO_ACTUAL";
export const USUARIO_LOGOUT="USUARIO_LOGOUT";


/* Mensaje de notificacion */
export const MOSTRAR_MENSAJE ="MOSTRAR_MENSAJE";

/* Mensaje de notificacion */
export const MOSTRAR_MENSAJE_TUTORIAL ="MOSTRAR_MENSAJE_TUTORIAL";


/* TIpo de dato y numero de registro en titulo */
export const TIPO_DE_DATO ="TIPO_DE_DATO";
export const SET_PROFIL ="SET_PROFIL";

//(05nov22) Se agrega estado para mostara la barra de busqueda.
export const MOSTRAR_BUSQUEDA="MOSTRAR_BUSQUEDA"

//(08dic22)  Se agrega modo de vista en el store de la aplicacion para el calendario
export const MODO_VISTA="MODO_VISTA"
