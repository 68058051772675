import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PRESION_ARTERIAL} from "../constants/action-type";
import { CONSULTAR_PRESION_ARTERIALES,AGREGAR_PRESION_ARTERIAL,ACTUALIZAR_PRESION_ARTERIAL,ELIMINAR_PRESION_ARTERIAL } from "../constants/PresionArterial-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createPresionArterialRestMiddleware =(presionArterialURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PRESION_ARTERIAL]: new RestDataSource(presionArterialURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de PresionArterialRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** PRESION_ARTERIAL INICIO */                                    
                case CONSULTAR_PRESION_ARTERIALES:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoPresionArterialByPaciente ={};
                        datoPresionArterialByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_PRESION_ARTERIALES(datoPresionArterialByPaciente):"+JSON.stringify(datoPresionArterialByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataPresionArterial,mensaje)=>{ 
                            if(dataPresionArterial===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_PRESION_ARTERIALES,
                                dataType:action.dataType,
                                payload:dataPresionArterial}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPresionArterial.length});
                                }
                            }
                        },datoPresionArterialByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_PRESION_ARTERIAL:
                    console.log("AGREGAR_PRESION_ARTERIAL(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataPresionArterial,mensaje)=>{ 
                        if(dataPresionArterial===null){
                            console.log("FALLA PETICION A PresionArterial:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_PRESION_ARTERIAL (data):"+dataPresionArterial);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_PRESION_ARTERIAL,
                                dataType:action.dataType,
                                payload:dataPresionArterial});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_PRESION_ARTERIAL:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataPresionArterial,mensaje)=>{ 
                            if(dataPresionArterial===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataPresionArterial):"+dataPresionArterial);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PRESION_ARTERIAL,
                                    dataType:action.dataType,
                                    payload:dataPresionArterial});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_PRESION_ARTERIAL:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataPresionArterial,mensaje)=>{ 
                        if(dataPresionArterial===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataPresionArterial):"+JSON.stringify(dataPresionArterial));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_PRESION_ARTERIAL,
                                dataType:action.dataType,
                                payload:dataPresionArterial});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createPresionArterialRestMiddleware;