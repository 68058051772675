import {CONSULTAR_CAT_TIPO_CITAES,AGREGAR_CAT_TIPO_CITA,ACTUALIZAR_CAT_TIPO_CITA, ELIMINAR_CAT_TIPO_CITA} from "../constants/CatTipoCita-action-type";


/* CAT_TIPO_CITAES (HOSPITAL CLINICA) */
export const obtenerCatTipoCitaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_CAT_TIPO_CITAES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarCatTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
