import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PERSONA, PACIENTE,CITA, CAT_TIPO_CITA,CAT_UNIDAD_MEDIDA} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES } from "../constants/CatTipoCita-action-type";
import { CONSULTAR_COLORES } from "../constants/Color-action-type";
import { CONSULTAR_CAT_UNIDAD_MEDIDAES } from "../constants/CatUnidadMedida-action-type";


/* Funcion para loggin llamada logMiddleware */
const createCatUnidadMedicaRestMiddleware =(catUnidadMedicaURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_UNIDAD_MEDIDA]: new RestDataSource(catUnidadMedicaURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de createCatUnidadMedicaRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** createCatUnidadMedicaRestMiddleware INICIO */                                    
                case CONSULTAR_CAT_UNIDAD_MEDIDAES:
                    if(action.loading){
                        next({type:PETICION_WS_SOLICITANDO});
                    }
                    dataSources[action.dataType].GetData((dataCatUnidadMedica,mensaje)=>{ 
                        console.log("dataCatUnidadMedica:"+dataCatUnidadMedica);
                        console.log("dataCatUnidadMedica:"+JSON.stringify(dataCatUnidadMedica));

                        if(dataCatUnidadMedica===null){
                            if(action.loading){
                                next({type:PETICION_WS_ERROR});
                            }
                        }else{
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_CAT_UNIDAD_MEDIDAES,
                                dataType:action.dataType,
                                payload:dataCatUnidadMedica
                            }); 
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatUnidadMedica.length});
                            }
                        }
                    });   
                break;   


        default:
                next(action);

    }
}

}

export default createCatUnidadMedicaRestMiddleware;