import {connect} from "react-redux";
import React,{Component} from "react";
import { CAT_ESTADO_CITA, CAT_TIPO_CITA, CITA, COLOR, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

 function SalasItem(props){

    let colorItem=[];
    if(props.catTipoCita!==undefined){
        colorItem=props.colores.filter(i => i.id === props.catTipoCita.COLOR_id)[0];
    }

    let codColor="";
    if(colorItem!==undefined){
        codColor=colorItem.color;
    }

    return(<Page cita={props.cita}
            paciente={props.paciente} 
            catTipoCita={props.catTipoCita}
            codColor={codColor}                         
            catEstadoCita={props.catEstadoCita}
            medico={props.medico}

           />)
}

const mapStateToProps=(state,ownProps)=>({
    colores:state.ColorReducer[COLOR],
    paciente: state.PacienteReducer[PACIENTE].filter(p => p.id === ownProps.cita.PACIENTE_id)[0],   
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
    catEstadoCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA].filter(p => p.id === ownProps.cita.CAT_ESTADO_CITA_id)[0],
    medico:state.MedicoReducer[MEDICO].filter(m => m.id === ownProps.cita.MEDICO_id)[0],

})

export default connect(mapStateToProps)(SalasItem);