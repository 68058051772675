import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, TECNICO} from "../constants/action-type";
import { CONSULTAR_TECNICOS,AGREGAR_TECNICO,ACTUALIZAR_TECNICO,ELIMINAR_TECNICO } from "../constants/Tecnico-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createTecnicoRestMiddleware =(TecnicoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [TECNICO]: new RestDataSource(TecnicoURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de TecnicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** TECNICO INICIO */                                    
                case CONSULTAR_TECNICOS:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoTecnicoByPaciente ={};
                        datoTecnicoByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_TECNICOS(datoTecnicoByPaciente):"+JSON.stringify(datoTecnicoByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataTecnico,mensaje)=>{ 
                            if(dataTecnico===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_TECNICOS,
                                dataType:action.dataType,
                                payload:dataTecnico}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataTecnico.length});
                                }
                            }
                        },datoTecnicoByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_TECNICO:
                    console.log("AGREGAR_TECNICO(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataTecnico,mensaje)=>{ 
                        if(dataTecnico===null){
                            console.log("FALLA PETICION A Tecnico:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_TECNICO (data):"+dataTecnico);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_TECNICO,
                                dataType:action.dataType,
                                payload:dataTecnico});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_TECNICO:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataTecnico,mensaje)=>{ 
                            if(dataTecnico===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataTecnico):"+dataTecnico);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_TECNICO,
                                    dataType:action.dataType,
                                    payload:dataTecnico});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_TECNICO:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataTecnico,mensaje)=>{ 
                        if(dataTecnico===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataTecnico):"+JSON.stringify(dataTecnico));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_TECNICO,
                                dataType:action.dataType,
                                payload:dataTecnico});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createTecnicoRestMiddleware;