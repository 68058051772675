import * as React from 'react';
import SliderItem from '../SliderItem';
import { Box, Container } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles({

  root: {
    position: "relative",
    overflow:"hidden",
    backgroundColor: "green",

  },
  row: {
    display:"flex",
    position: "relative",
    backgroundColor: "yellow",
  },
  row: {
    display:"flex",
    position: "relative",
    backgroundColor: "gray",
    padding:"0 30px",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tr:{
    background: "#f1f1f1",
    '&:hover': {
      background: "#f00",
    }
  }
});
//        <KeyboardArrowDownIcon />


export default function Page(props) {
  const classes = useStyles();
  const type="left";

  return ( 
       <div  >
       </div>                
  ) 
  
}