import {CONSULTAR_COLORES,AGREGAR_COLOR,ACTUALIZAR_COLOR, ELIMINAR_COLOR} from "../constants/Color-action-type";


/* COLORES (HOSPITAL CLINICA) */
export const obtenerColorListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_COLORES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarColorWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_COLOR,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarColorWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_COLOR,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarColorWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_COLOR,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
