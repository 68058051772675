import {CONSULTAR_CITAES, AGREGAR_CITA, ACTUALIZAR_CITA,ELIMINAR_CITA} from "../constants/Cita-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (CITAES):"+action.type);

    switch(action.type){
        
        case CONSULTAR_CITAES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_CITA:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER CITAES");
            return state||estadoInicial.datos;
        

    }
}
