import {PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.estadosPeticionWS,action){

    console.log("ESTADO (PETICION EXITOSA) "+action.dataType);
    switch(action.type){
        
/* INVOCANDO SERVIVIOS */
        case PETICION_WS_SOLICITANDO:
            return {
                ...state,
                loading:true
            }  

        case PETICION_WS_EXITOSA:
            return {
                ...state,
                loading:false,
            }  

        case PETICION_WS_ERROR:
            return {
                ...state,
                loading:false
            }  
    

        default:
            return state||estadoInicial.estadosPeticionWS;
    }

}