import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, COMIDA} from "../constants/action-type";
import { CONSULTAR_COMIDAS,AGREGAR_COMIDA,ACTUALIZAR_COMIDA,ELIMINAR_COMIDA} from "../constants/Comida-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createComidaRestMiddleware =(ComidaURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [COMIDA]: new RestDataSource(ComidaURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de ComidaRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** COMIDAS INICIO */                                    
                case CONSULTAR_COMIDAS:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoComidaByPaciente ={};
                        datoComidaByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_COMIDAS(datoComidaByPaciente):"+JSON.stringify(datoComidaByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataComida,mensaje)=>{ 
                            if(dataComida===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_COMIDAS,
                                dataType:action.dataType,
                                payload:dataComida}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataComida.length});
                                }
                            }
                        },datoComidaByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_COMIDA:
                    console.log("AGREGAR_COMIDAS(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataComida,mensaje)=>{ 
                        if(dataComida===null){
                            console.log("FALLA PETICION A Comida:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_COMIDAS (data):"+dataComida);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_COMIDA,
                                dataType:action.dataType,
                                payload:dataComida});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_COMIDA:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataComida,mensaje)=>{ 
                            if(dataComida===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataComida):"+dataComida);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_COMIDA,
                                    dataType:action.dataType,
                                    payload:dataComida});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_COMIDA:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataComida,mensaje)=>{ 
                        if(dataComida===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataComida):"+JSON.stringify(dataComida));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_COMIDA,
                                dataType:action.dataType,
                                payload:dataComida});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createComidaRestMiddleware;