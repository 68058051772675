import {RestDataSource} from "./RestDataSource";
import { TIPO_DE_DATO, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         CAT_ROL} from "../constants/action-type";
import { CONSULTAR_CAT_ROLES } from "../constants/CatRol-action-type";


/* Funcion para loggin llamada logMiddleware */
const createCatRolRestMiddleware =(catRolURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_ROL]: new RestDataSource(catRolURL),

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de createCatRolRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_CAT_ROLES:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                            dataSources[action.dataType].GetData((dataCatRoles,mensaje)=>{ 
                                if(dataCatRoles===null){
                                    if(action.loading){next({type:PETICION_WS_ERROR});}
                                }else{
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:CONSULTAR_CAT_ROLES,
                                    dataType:action.dataType,
                                    payload:dataCatRoles}); 
                                    if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatRoles.length});
                                    }
                                }
                            });   
                break;    

        default:
                next(action);

    }
}

}

export default createCatRolRestMiddleware;