import {CONSULTAR_PRESION_ARTERIALES, AGREGAR_PRESION_ARTERIAL, ACTUALIZAR_PRESION_ARTERIAL,ELIMINAR_PRESION_ARTERIAL} from "../constants/PresionArterial-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (PRESION_ARTERIALES):"+action.type);

    switch(action.type){
        
        case CONSULTAR_PRESION_ARTERIALES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_PRESION_ARTERIAL:
            return{
                ...state,
             //   [action.dataType]:state[action.dataType].concat(action.payload)
                  [action.dataType]:[action.payload].concat(state[action.dataType]) //26Feb21Vie. agregar elemento al principio del array

            }

        case ACTUALIZAR_PRESION_ARTERIAL:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_PRESION_ARTERIAL:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER PRESION_ARTERIALES");
            return state||estadoInicial.datos;
        

    }
}
