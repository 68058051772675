import {CONSULTAR_MEDICOS, AGREGAR_MEDICO, ACTUALIZAR_MEDICO,ELIMINAR_MEDICO} from "../constants/Medico-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (MEDICOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_MEDICOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_MEDICO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_MEDICO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_MEDICO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER MEDICOS");
            return state||estadoInicial.datos;
        

    }
}
