import React, {Component} from "react";
import {connect} from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

export default function(ComponenteCompuesto){

    class Autenticaion extends Component{

        render(){
            console.log("autenticado :"+this.props.autenticado)

            return (<div>
                        {this.props.autenticado?<ComponenteCompuesto {...this.props}/>:<Redirect to="login" />}
                </div>
            );
        }
    } 

    const mapStateToProps=(state)=>({
        autenticado:state.autenticacionReducer.autenticado, 
        usuario:state.autenticacionReducer.usuario 
    
    })

    
    
    return  connect(mapStateToProps)(Autenticaion);

}