import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, GLUCOSA} from "../constants/action-type";
import { CONSULTAR_GLUCOSAES,AGREGAR_GLUCOSA,ACTUALIZAR_GLUCOSA,ELIMINAR_GLUCOSA } from "../constants/Glucosa-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";

/* Funcion para loggin llamada logMiddleware */
const createGlucosaRestMiddleware =(glucosaURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [GLUCOSA]: new RestDataSource(glucosaURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de GlucosaRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** Glucosa INICIO */                                    
                case CONSULTAR_GLUCOSAES:
                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoGlucosaByPaciente ={};
                        datoGlucosaByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_GLUCOSAES(datoGlucosaByPaciente):"+JSON.stringify(datoGlucosaByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataGlucosa,mensaje)=>{ 
                            if(dataGlucosa===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_GLUCOSAES,
                                dataType:action.dataType,
                                payload:dataGlucosa}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataGlucosa.length});
                                }
                            }
                        },datoGlucosaByPaciente);    
                    }  
                  
                break;   

                case AGREGAR_GLUCOSA:
                    console.log("AGREGAR_GLUCOSA(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataGlucosa,mensaje)=>{ 
                        if(dataGlucosa===null){
                            console.log("FALLA PETICION A Glucosa:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_GLUCOSA (data):"+dataGlucosa);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_GLUCOSA,
                                dataType:action.dataType,
                                payload:dataGlucosa});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_GLUCOSA:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataGlucosa,mensaje)=>{ 
                            if(dataGlucosa===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataGlucosa):"+dataGlucosa);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_GLUCOSA,
                                    dataType:action.dataType,
                                    payload:dataGlucosa});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_GLUCOSA:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataGlucosa,mensaje)=>{ 
                        if(dataGlucosa===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataGlucosa):"+JSON.stringify(dataGlucosa));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_GLUCOSA,
                                dataType:action.dataType,
                                payload:dataGlucosa});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createGlucosaRestMiddleware;
               