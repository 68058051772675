import {CONSULTAR_CAT_TIPO_CITAES,CONSULTAR_CAT_TIPO_CITAES_BY_ID, AGREGAR_CAT_TIPO_CITA, ACTUALIZAR_CAT_TIPO_CITA,ELIMINAR_CAT_TIPO_CITA} from "../constants/CatTipoCita-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (CAT_TIPO_CITAES):"+action.type);

    switch(action.type){
        
        case CONSULTAR_CAT_TIPO_CITAES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
        //CON EL FIN DE QUE NO SE EJEUCTE NUEVAMENTE LA ACCION DEFINIDA EN CASE DE MIDDLEWARE            
        case CONSULTAR_CAT_TIPO_CITAES_BY_ID: 
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
    
        case AGREGAR_CAT_TIPO_CITA:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_CAT_TIPO_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_CAT_TIPO_CITA:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER CAT_TIPO_CITAES");
            return state||estadoInicial.datos;
        

    }
}
