import React,{Component} from "react";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import logoBS from '../../../logo/logo.ico'
import { Avatar, Chip, Paper } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default class LogoFloat extends Component{



    render(){     
 
      return   <Fab      
                position="fixed"
                variant="circular"
                size="large"
                color="primary"
                aria-label="agregar"
                style={{position: 'fixed', bottom: "3.5rem", right: "6rem",boxShadow:"10",backgroundColor:"#FFFFFF",width: "7rem",height: "7rem"}}
                component={Link}  to={{
                  hash:'id', 
                  valores:{
                  modal:true
                }
                }} 
              >
                <Chip
                        avatar={<Avatar alt="Bildsoft"  src={logoBS}  style={{width: "6.5rem",height: "6.5rem",borderColor:"whitesmoke"}}/>}
                        label=""
                        variant="default"
                        style={{backgroundColor:"transparent"}}
                    />
              </Fab>
     
                   
    }                          
}
