import { USUARIO_ACTUAL,USUARIO_LOGOUT} from "../constants/action-type";


/* AUTENTICACION */

export const agregarUsuarioActual=(payload,profil)=>{
    return {
        type:USUARIO_ACTUAL,
        payload:payload,
        profil:profil
        }
}

/* login */
export const logout=()=>({
    type:USUARIO_LOGOUT
})
