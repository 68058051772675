import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PRODUCTO} from "../constants/action-type";
import { CONSULTAR_PRODUCTOS,AGREGAR_PRODUCTO,ACTUALIZAR_PRODUCTO,ELIMINAR_PRODUCTO } from "../constants/Producto-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createProductoRestMiddleware =(ProductoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PRODUCTO]: new RestDataSource(ProductoURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de ProductoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** PRODUCTO INICIO */                                    
                case CONSULTAR_PRODUCTOS:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoProductoByPaciente ={};
                        datoProductoByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_PRODUCTOS(datoProductoByPaciente):"+JSON.stringify(datoProductoByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataProducto,mensaje)=>{ 
                            if(dataProducto===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_PRODUCTOS,
                                dataType:action.dataType,
                                payload:dataProducto}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataProducto.length});
                                }
                            }
                        },datoProductoByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_PRODUCTO:
                    console.log("AGREGAR_PRODUCTO(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataProducto,mensaje)=>{ 
                        if(dataProducto===null){
                            console.log("FALLA PETICION A Producto:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_PRODUCTO (data):"+dataProducto);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_PRODUCTO,
                                dataType:action.dataType,
                                payload:dataProducto});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_PRODUCTO:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataProducto,mensaje)=>{ 
                            if(dataProducto===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataProducto):"+dataProducto);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PRODUCTO,
                                    dataType:action.dataType,
                                    payload:dataProducto});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_PRODUCTO:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataProducto,mensaje)=>{ 
                        if(dataProducto===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataProducto):"+JSON.stringify(dataProducto));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_PRODUCTO,
                                dataType:action.dataType,
                                payload:dataProducto});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createProductoRestMiddleware;