import {CONSULTAR_INSTITUCIONES, AGREGAR_INSTITUCION, ACTUALIZAR_INSTITUCION,ELIMINAR_INSTITUCION, CONSULTAR_INSTITUCIONES_LOCAL_STORAGE} from "../constants/Institucion-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (INSTITUCIONES):"+action.type);

    switch(action.type){
        
        case CONSULTAR_INSTITUCIONES_LOCAL_STORAGE:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
        case CONSULTAR_INSTITUCIONES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_INSTITUCION:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_INSTITUCION:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_INSTITUCION:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER INSTITUCIONES");
            return state||estadoInicial.datos;
        

    }
}
