import * as React from 'react';
import { Box, CardHeader, Chip, Container, IconButton } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SlideButton from '../SliderButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import AirlineSeatFlatTwoToneIcon from '@material-ui/icons/AirlineSeatFlatTwoTone';
import { ESTATUS_LISTO_EN_SALA } from '../../../utils/constantes';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import camaPNG from "../../../icons/cama.png";
import cuartoCirugiaPNG from "../../../icons/operacion.png";
import informacionPersonalPNG from "../../../icons/paciente.png";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  card: {
    height:"18rem",
    width:"46rem",
    borderRadius: 15,
    position: 'relative',
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor:"silver",
 },
  media: {
    height: 40,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Page(props) {
  const classes = useStyles();

  let nombrePaciente="";
  let nombreMedico="";
  let tipoCita="";
  let estatusCita="";
  let edad="";
  let codColorStatus=""
  let colorFace="#90a4ae";

  let elevacion=3;
  let border="";
  let transform= "";
  let horario="";


  
  if(props.paciente!=undefined){
    nombrePaciente=<Typography variant='h6' style={{ textTransform:"uppercase",fontWeight:"bolder",padding:"3px 0"}}>
                    {props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno} 
                   </Typography>
  }

  if(props.paciente!==undefined){
    edad=<Typography variant='h6' style={{ }}>
          {props.paciente.edad+"a."}
        </Typography>
    
  }

  if(props.medico!==undefined){
    nombreMedico=<Typography variant='h6' style={{ textTransform:"uppercase"}}>
                  {props.medico.titulo+" "+props.medico.nombres+" "+props.medico.apellidoPaterno}
                 </Typography>
  }
  if(props.catTipoCita!==undefined){
    tipoCita=<Typography variant='h6' style={{ textTransform:"uppercase" ,color:  props.codColor ,}}>
              {props.catTipoCita.nombre}
             </Typography>
  }

  if(props.catEstadoCita!==undefined){
    estatusCita=props.catEstadoCita.nombre;
    codColorStatus=props.catEstadoCita.codColor;
  }

  if(props.cita.horaInicio!==undefined){
    horario=<Chip  
              variant="default"                                           
              icon={<QueryBuilderIcon style={{color:"whitesmoke"}}/>} 
              label={  <Typography variant='h6' style={{ textTransform:"uppercase"}}>{props.cita.horaInicio.substring(0,5)+" - "+props.cita.horaFin.substring(0,5)}</Typography>} 
              style={{maxWidth: 210,backgroundColor:"#009688",color:"WHITE"}} 
            
            />
  }
  
  if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA){ 
    elevacion=5;
    border= "solid 4px blue";
    transform= "scale(1.1)";

  }
  return ( 
       <div className={classes.root} >
         <Card className={classes.card} elevation={5}>
          
         <div style={{display:"flex"}}>
          <CardContent>         
            <CardHeader
              avatar={
                <img alt="Remy Sharp" src={cuartoCirugiaPNG} className={classes.large} />

              }
              action={
                <IconButton aria-label="settings">
                </IconButton>
              }
              title={<Typography variant='h6' style={{ textTransform:"uppercase"}}>
                      SALA 1
                     </Typography>}
                
            />


          </CardContent>

          <CardContent style={{display:"grid"}}>         
            <CardHeader
                avatar={
                  <img alt="Remy Sharp" src={informacionPersonalPNG} className={classes.large} />

                }
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                title={nombrePaciente}
                subheader={edad}
              />
            {nombreMedico}
           
            {tipoCita}
           
           {horario}

          </CardContent>
          </div>
         
        </Card>
       </div>                
  ) 
  
}