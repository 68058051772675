import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PERSONA, PACIENTE,CITA, CAT_TIPO_CITA,COLOR,ESQUEMA_TIPO_CITA, ESQUEMA_TRABAJO} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES } from "../constants/CatTipoCita-action-type";
import { CONSULTAR_ESQUEMA_TIPO_CITAES_BY_ESQUEMA,AGREGAR_ESQUEMA_TIPO_CITA, CONSULTAR_ESQUEMA_TIPO_CITAES, ELIMINAR_ESQUEMA_TIPO_CITA } from "../constants/EsquemaTipoCita-action-type";
import {PROFIL_ADMINISTRADOR, PROFIL_MEDICO} from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createEsquemaTipoCitaRestMiddleware =(esquemaTipoCitaURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [ESQUEMA_TIPO_CITA]: new RestDataSource(esquemaTipoCitaURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);
    let profil=getState().autenticacionReducer.profil;

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */   
                case CONSULTAR_ESQUEMA_TIPO_CITAES_BY_ESQUEMA:     
                console.log("CONSULTAR_ESQUEMA_TIPO_CITAES_BY_ESQUEMA(payload):"+action.payload);
                var datosIdByEsquema ={};
                    datosIdByEsquema['ESQUEMA_TRABAJO_id']=action.payload;
                    console.log("datosIdByEsquema:"+JSON.stringify(datosIdByEsquema));
                    console.log("datosIdByEsquema(action.loading):"+action.loading);

                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[action.dataType].GetDataParams((dataEsquemaTipoCita,mensaje)=>{ 
                        if(dataEsquemaTipoCita===null){
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:CONSULTAR_ESQUEMA_TIPO_CITAES,
                            dataType:action.dataType,
                            payload:dataEsquemaTipoCita}); 
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquemaTipoCita.length});
                            }
                        }
                    },datosIdByEsquema);       
                

                break;  
                            
                case CONSULTAR_ESQUEMA_TIPO_CITAES:
                //19Jul21 Se actualiza para realizar consulta para el perfil MEDICO
                 console.log("PERFIL DEL USUARIO:"+profil);
                 if(profil===PROFIL_MEDICO){ //SE REALIZA LA BUSQUEDA DE LAS CITAS DEL MEDICO POR SU ID.
                   // if(getState().EsquemaTrabajoReducer[ESQUEMA_TRABAJO][0]!==undefined){

                    var datosIdByMedico ={};
                    datosIdByMedico['MEDICO_id']=getState().MedicoUsuarioReducer.medicoUsuario[0].MEDICO_id;
                    console.log("datosIdByMedico:"+JSON.stringify(datosIdByMedico));
                    console.log("datosIdByMedico(action.loading):"+action.loading)
    
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataEsquemaTipoCita,mensaje)=>{ 
                            if(dataEsquemaTipoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_ESQUEMA_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataEsquemaTipoCita}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquemaTipoCita.length});
                                }
                            }
                        },datosIdByMedico);       
                    //}
                 }else if(profil===PROFIL_ADMINISTRADOR){ //(26junio) Se agrega consulta que envia el INSTITICION_id y nos retorna todos los esquemas existentes de cada medico.
                    var datosIdByInstitucion ={};
                        
                    datosIdByInstitucion['INSTITUCION_id']=getState().InstitucionReducer.instituciones[0].id;
                    console.log("datosIdByInstitucion:"+JSON.stringify(datosIdByInstitucion));
                    console.log("datosIdByInstitucion(action.loading):"+action.loading);

                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataEsquemaTipoCita,mensaje)=>{ 
                            if(dataEsquemaTipoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_ESQUEMA_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataEsquemaTipoCita}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquemaTipoCita.length});
                                }
                            }
                        },datosIdByInstitucion);       
                    
                 } 


                break;  
                case AGREGAR_ESQUEMA_TIPO_CITA:
                    console.log("PAYLOAD (AGREGAR_ESQUEMA_TIPO_CITA):::>><<"+JSON.stringify(action.payload));

                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[ESQUEMA_TIPO_CITA].SetData((dataEsquemaTipoCita,mensaje)=>{ 
                        if(dataEsquemaTipoCita===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_ESQUEMA_TIPO_CITA,
                                dataType:ESQUEMA_TIPO_CITA,
                                payload:dataEsquemaTipoCita});
                           /*****/
                            next({type:PETICION_WS_EXITOSA});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);
                break;  

                case ELIMINAR_ESQUEMA_TIPO_CITA:
                    console.log("PAYLOAD (ELIMINAR_ESQUEMA_TIPO_CITA):::>><<"+JSON.stringify(action.payload));
                   if(profil===PROFIL_ADMINISTRADOR){ 
                        next({type:PETICION_WS_SOLICITANDO});    
                        var id={};
                        id["id"]=action.payload.id;
                        dataSources[action.dataType].DeleteDataParams((dataEsquemaTipoCita,mensaje)=>{ 
                            if(dataEsquemaTipoCita===null){
                                console.log("FALLA PETICION A AGREGAR_CITA:"+mensaje);
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                                console.log("ELIMINAR_ESQUEMA_TIPO_CITA (data):"+dataEsquemaTipoCita);
                                if(dataEsquemaTipoCita!==""){
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:ELIMINAR_ESQUEMA_TIPO_CITA,
                                        dataType:action.dataType,
                                        payload:action.payload});
                                        
                                    let count=parseInt( getState().estadosReducer.registros) - 1;
                                    console.log("coun:"+count); 
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
        
                                }
            
                            }
                        },id);                                               
                            
                    }
      
                break;  
        default:
                next(action);

    }
}

}

export default createEsquemaTipoCitaRestMiddleware;