import {CONSULTAR_ESTADOS, AGREGAR_ESTADO, ACTUALIZAR_ESTADO,ELIMINAR_ESTADO} from "../constants/Estado-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (ESTADOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_ESTADOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_ESTADO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_ESTADO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_ESTADO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER ESTADOS");
            return state||estadoInicial.datos;
        

    }
}
