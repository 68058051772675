import {CONSULTAR_CAT_UNIDAD_MEDIDAES, AGREGAR_CAT_UNIDAD_MEDIDA, ACTUALIZAR_CAT_UNIDAD_MEDIDA,ELIMINAR_CAT_UNIDAD_MEDIDA} from "../constants/CatUnidadMedida-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (CAT_UNIDAD_MEDIDAES):"+action.type);
    console.log("Dentro de REDUCERS (CAT_UNIDAD_MEDIDAES) :(action.dataType)"+action.dataType);
    console.log("Dentro de REDUCERS (CAT_UNIDAD_MEDIDAES) :(action.payload)"+action.payload);

    switch(action.type){
        
        case CONSULTAR_CAT_UNIDAD_MEDIDAES:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_CAT_UNIDAD_MEDIDA:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_CAT_UNIDAD_MEDIDA:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_CAT_UNIDAD_MEDIDA:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER CAT_UNIDAD_MEDIDAES");
            return state||estadoInicial.datos;
        

    }
}
