import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, MOVIMIENTO} from "../constants/action-type";
import { CONSULTAR_MOVIMIENTOS,AGREGAR_MOVIMIENTO,ACTUALIZAR_MOVIMIENTO,ELIMINAR_MOVIMIENTO } from "../constants/Movimiento-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createMovimientoRestMiddleware =(MovimientoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [MOVIMIENTO]: new RestDataSource(MovimientoURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MovimientoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** MOVIMIENTO INICIO */                                    
                case CONSULTAR_MOVIMIENTOS:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoMovimientoByPaciente ={};
                        datoMovimientoByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_MOVIMIENTOS(datoMovimientoByPaciente):"+JSON.stringify(datoMovimientoByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataMovimiento,mensaje)=>{ 
                            if(dataMovimiento===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_MOVIMIENTOS,
                                dataType:action.dataType,
                                payload:dataMovimiento}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataMovimiento.length});
                                }
                            }
                        },datoMovimientoByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_MOVIMIENTO:
                    console.log("AGREGAR_MOVIMIENTO(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataMovimiento,mensaje)=>{ 
                        if(dataMovimiento===null){
                            console.log("FALLA PETICION A Movimiento:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_MOVIMIENTO (data):"+dataMovimiento);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_MOVIMIENTO,
                                dataType:action.dataType,
                                payload:dataMovimiento});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_MOVIMIENTO:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataMovimiento,mensaje)=>{ 
                            if(dataMovimiento===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataMovimiento):"+dataMovimiento);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_MOVIMIENTO,
                                    dataType:action.dataType,
                                    payload:dataMovimiento});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_MOVIMIENTO:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataMovimiento,mensaje)=>{ 
                        if(dataMovimiento===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataMovimiento):"+JSON.stringify(dataMovimiento));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_MOVIMIENTO,
                                dataType:action.dataType,
                                payload:dataMovimiento});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createMovimientoRestMiddleware;