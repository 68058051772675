import {CONSULTAR_HORARIOS, AGREGAR_HORARIO, ACTUALIZAR_HORARIO,ELIMINAR_HORARIO} from "../constants/Horario-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (HORARIOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_HORARIOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_HORARIO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_HORARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_HORARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER HORARIOS");
            return state||estadoInicial.datos;
        

    }
}
