import {CONSULTAR_MEDICOS,AGREGAR_MEDICO,ACTUALIZAR_MEDICO, ELIMINAR_MEDICO} from "../constants/Medico-action-type";


/* MEDICOS (HOSPITAL CLINICA) */
export const obtenerMedicoListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_MEDICOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarMedicoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_MEDICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarMedicoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_MEDICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarMedicoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_MEDICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
