import {connect} from "react-redux";
import React,{Component} from "react";
import { CAT_TIPO_CITA, CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

function SliderFilter(props){

    return(<Page  
                catTipoCita={props.catTipoCita}

            />)
}


const mapStateToProps=(state,ownProps)=>({
      //  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
        catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],

    })
    
const mapDispatchToProps=(dispatch)=>({

})
    
export default connect(mapStateToProps,mapDispatchToProps)(SliderFilter);