import { MODO_VISTA_AGENDADOS, MODO_VISTA_MES } from "../../utils/constantes";
import {PACIENTE, INSTITUCION, USUARIO,MEDICO_USUARIO,ESTADO, MEDICO, PERSONA, CITA, CAT_TIPO_CITA, CAT_ESTADO_CITA, RECORDATORIO, ESQUEMA_TRABAJO, CAT_DIA_SEMANA, COLOR, ESQUEMA_TIPO_CITA, PERIODO, HORARIO, ESQUEMA_TIPO_CITA_HORARIO, CAT_ROL, INSTITUCION_PACIENTE,CAT_SEXO, PACIENTE_USUARIO, PRESION_ARTERIAL, GLUCOSA, CAT_UNIDAD_MEDIDA,
COMIDA,PAGINA_WEB,PRODUCTO,PROCEDIMIENTO,MOVIMIENTO,TECNICO, INSTITUCION_MEDICO} from "../constants/action-type";


export const estadoInicial={
    datos:{
        [PACIENTE]:[],
        [INSTITUCION]:[],
        [INSTITUCION_PACIENTE]:[],
        [INSTITUCION_MEDICO]:[],
        [USUARIO]:[],
        [MEDICO_USUARIO]:[],
        [PACIENTE_USUARIO]:[],
        [ESTADO]:[],
        [MEDICO]:[],
        [CITA]:[],
        [CAT_TIPO_CITA]:[],
        [CAT_ESTADO_CITA]:[],
        [CAT_DIA_SEMANA]:[],
        [CAT_ROL]:[],
        [CAT_SEXO]:[],
        [CAT_UNIDAD_MEDIDA]:[],
        [RECORDATORIO]:[],
        [ESQUEMA_TRABAJO]:[],
        [COLOR]:[],
        [ESQUEMA_TIPO_CITA]:[],
        [PERIODO]:[],
        [HORARIO]:[],
        [ESQUEMA_TIPO_CITA_HORARIO]:[],
        [PRESION_ARTERIAL]:[],
        [GLUCOSA]:[],
        [COMIDA]:[],
        [PAGINA_WEB]:[],
        [PRODUCTO]:[],
        [PROCEDIMIENTO]:[],
        [MOVIMIENTO]:[],
        [TECNICO]:[],
    },
    estados:{
        selectedType:CITA,
        registros:0,
        mostrarBusqueda:false,//(05nov22 ) Se agrega estado para mostara la barra de busqueda.
        modoVista:MODO_VISTA_AGENDADOS
    },
    estadosPeticionWS:{
        loading: false
    },
    estadosMensaje:{
        mensaje:"Operacion exitosa!",
        tipoMensaje:"info",
        abrirMensaje:false
    },
    tutorialMensaje:{
        mensaje:"",
        tipoMensaje:"",
        abrirMensaje:false
    },
    login:{
        id:"",
        usuario:"",
        loading:false,
        errors:""
    },
    autenticacion:{
        autenticado:false,
        usuario:{},
        profil:"publico"
    }
}