import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PAGINA_WEB,PAGINA_WEB_IMAGEN} from "../constants/action-type";
import { CONSULTAR_PAGINA_WEBES,AGREGAR_PAGINA_WEB,ACTUALIZAR_PAGINA_WEB,ELIMINAR_PAGINA_WEB, AGREGAR_IMAGEN_PAGINA_WEB } from "../constants/PaginaWeb-action-type";
import { PROFIL_MEDICO } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createPaginaWebRestMiddleware =(PaginaWebURL,PaginaWebImagenURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PAGINA_WEB]: new RestDataSource(PaginaWebURL),
        [PAGINA_WEB_IMAGEN]: new RestDataSource(PaginaWebImagenURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de PaginaWebRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** PAGINA_WEB INICIO */                                    
                case CONSULTAR_PAGINA_WEBES:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_MEDICO){ //SE REALIZA LA BUSQUEDA DE LAS CITAS DEL MEDICO POR SU ID.
                        var datosTipoCitasByMedico ={};
                        datosTipoCitasByMedico['MEDICO_id']=getState().MedicoUsuarioReducer.medicoUsuario[0].MEDICO_id;

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataPaginaWeb,mensaje)=>{ 
                            if(dataPaginaWeb===null){
                                console.log("FALLA PETICION A PaginaWeb:"+mensaje);
                                if(action.loading){
                                    next({type:PETICION_WS_ERROR});
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                                }
                                
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_PAGINA_WEBES,
                                dataType:action.dataType,
                                payload:dataPaginaWeb}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataPaginaWeb.length});
                                }
                            }
                        },datosTipoCitasByMedico);   
                    
                    }
 
                 

                  
                break;   

                case AGREGAR_PAGINA_WEB:
                    console.log("AGREGAR_PAGINA_WEB(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataPaginaWeb,mensaje)=>{ 
                        if(dataPaginaWeb===null){
                            console.log("FALLA PETICION A PaginaWeb:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_PAGINA_WEB (data):"+dataPaginaWeb);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_PAGINA_WEB,
                                dataType:action.dataType,
                                payload:dataPaginaWeb});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case AGREGAR_IMAGEN_PAGINA_WEB:
                    console.log("AGREGAR_IMAGEN_PAGINA_WEB(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[PAGINA_WEB_IMAGEN].SetDataFile((dataPaginaWeb,mensaje)=>{ 
                        if(dataPaginaWeb===null){
                            console.log("FALLA PETICION A PaginaWeb:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_IMAGEN_PAGINA_WEB (data):"+dataPaginaWeb);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_IMAGEN_PAGINA_WEB,
                                dataType:action.dataType,
                                payload:dataPaginaWeb});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_PAGINA_WEB:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataPaginaWeb,mensaje)=>{ 
                            if(dataPaginaWeb===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataPaginaWeb):"+dataPaginaWeb);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PAGINA_WEB,
                                    dataType:action.dataType,
                                    payload:dataPaginaWeb});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_PAGINA_WEB:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataPaginaWeb,mensaje)=>{ 
                        if(dataPaginaWeb===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataPaginaWeb):"+JSON.stringify(dataPaginaWeb));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_PAGINA_WEB,
                                dataType:action.dataType,
                                payload:dataPaginaWeb});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createPaginaWebRestMiddleware;