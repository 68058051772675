import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PACIENTE,CITA, CAT_TIPO_CITA,MOSTRAR_MENSAJE_TUTORIAL} from "../constants/action-type";
import { CONSULTAR_CITAES } from "../constants/Cita-action-type";
import { CONSULTAR_CAT_TIPO_CITAES, AGREGAR_CAT_TIPO_CITA, ACTUALIZAR_CAT_TIPO_CITA, ELIMINAR_CAT_TIPO_CITA } from "../constants/CatTipoCita-action-type";
import {PROFIL_ADMINISTRADOR, PROFIL_MEDICO} from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createCatTipoCitaRestMiddleware =(catTipoCitaURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [CAT_TIPO_CITA]: new RestDataSource(catTipoCitaURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);
    let profil=getState().autenticacionReducer.profil;

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_CAT_TIPO_CITAES:

                    console.log("PERFIL DEL USUARIO:"+profil);
                    if(profil===PROFIL_MEDICO){ //SE REALIZA LA BUSQUEDA DE LAS CITAS DEL MEDICO POR SU ID.
                       console.log("DESDE CONSULTAR_CAT_TIPO_CITAES 2:"+JSON.stringify(getState().MedicoUsuarioReducer.medicoUsuario[0].MEDICO_id));

                       var datosTipoCitasByInstitucion ={};
                       datosTipoCitasByInstitucion['INSTITUCION_id']=getState().InstitucionReducer.instituciones[0].id;

                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataCatTipoCita,mensaje)=>{ 
                            if(dataCatTipoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_CAT_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataCatTipoCita});    
                                
                                console.log("dataCatTipoCita Login (id):"+JSON.stringify(dataCatTipoCita));
                                console.log("dataCatTipoCita Login (id):"+dataCatTipoCita.length);
                                //Si el perfil medico no contiene tipos de citas creadas length == 0 se muestra mensaje de tutorial        
                                /*if(dataCatTipoCita.length===0){
                                    console.log("TUTORIAL INICAIALtutorialMensajeReducer");
                                    next({type:MOSTRAR_MENSAJE_TUTORIAL,abrirMensaje:true,mensaje:"Agregar Tipo de cita"});                             
                                }
                                  */  if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatTipoCita.length});
                                    } 
                                    /*
                                */
                                
                            }
                        },datosTipoCitasByInstitucion)
                    }if(profil===PROFIL_ADMINISTRADOR){ //(26junio21)Se obtienen los tipos de cita por Institucion //ya no por medico
                        console.log("DESDE PROFIL_ADMINISTRADOR:"+JSON.stringify(getState().InstitucionReducer.instituciones[0].id));
                        var datosTipoCitasByInstitucion ={};
                        datosTipoCitasByInstitucion['INSTITUCION_id']=getState().InstitucionReducer.instituciones[0].id;
                        if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetDataParams((dataCatTipoCita,mensaje)=>{ 
                            if(dataCatTipoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_CAT_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataCatTipoCita});    
                                
                                console.log("dataCatTipoCita Login (id):"+JSON.stringify(dataCatTipoCita));
                                console.log("dataCatTipoCita Login (id):"+dataCatTipoCita.length);
                                //Si el perfil medico no contiene tipos de citas creadas length == 0 se muestra mensaje de tutorial        
                                /*if(dataCatTipoCita.length===0){
                                    console.log("TUTORIAL INICAIALtutorialMensajeReducer");
                                    next({type:MOSTRAR_MENSAJE_TUTORIAL,abrirMensaje:true,mensaje:"Agregar Tipo de cita"});                             
                                }
                                  */  if(action.loading){
                                        next({type:PETICION_WS_EXITOSA});
                                        next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatTipoCita.length});
                                    } 
                                    /*
                                */
                                
                            }
                        },datosTipoCitasByInstitucion)

                    }else{
                      /*  if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                        dataSources[action.dataType].GetData((dataCatEstadoCita,mensaje)=>{ 
                            if(dataCatEstadoCita===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_CAT_TIPO_CITAES,
                                dataType:action.dataType,
                                payload:dataCatEstadoCita}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataCatEstadoCita.length});
                                }
                            }
                        }); */
                    } // SI EL PERFIL ES ASISTETE SE REALZA LA BUSQUEDA POR (ID DE INSTITUCION CONSULTA PARA OBTENER LOS IDS DE LOS MEDICOS Y MOSTRAR POR ID).

  
                break;   

                case AGREGAR_CAT_TIPO_CITA: //EL TIPO DE CITA ES AGREGADO SOLO POR EL PERFIL MEDICO EL CUAL CONTIENE EL MEDICO_id //CAMBIAR PARA ADMINSTRADOR.
                    next({type:PETICION_WS_SOLICITANDO});
                    console.log("AGREGAR_CAT_TIPO_CITA (cita):"+JSON.stringify(action.payload));
                    dataSources[action.dataType].SetData((data,mensaje)=>{ 
                        if(data===null){
                            console.log("FALLO PETICION A PERSONA:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                        }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_CAT_TIPO_CITA,
                                dataType:action.dataType,
                                payload:data});
                            console.log("RESPUESTA SERVIDOR:"+JSON.stringify(data));
                            
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."});                             
                        }
                    },action.payload);                                

                 break; 

                 case ACTUALIZAR_CAT_TIPO_CITA:
                    if(action.loading){
                        next({type:PETICION_WS_SOLICITANDO});
                    }
                    console.log("ACTUALIZAR_CAT_TIPO_CITA (cat tipo cita):"+JSON.stringify(action.payload));
                    dataSources[action.dataType].UpdateData((data,mensaje)=>{ 
                        if(data===null){
                            if(action.loading){
                                next({type:PETICION_WS_ERROR});
                            }
                        }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ACTUALIZAR_CAT_TIPO_CITA,
                                dataType:action.dataType,
                                payload:action.payload}); //(04Ago21) se cambia por el payload ya que el servicion web solo regresa un valor correcto
                            console.log("RESPUESTA SERVIDOR:"+JSON.stringify(data));                            
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."});                             
                            }
                        }
                    },action.payload); 

                    break; 

                    case ELIMINAR_CAT_TIPO_CITA:
                        next({type:PETICION_WS_SOLICITANDO});
    
                        console.log("ELIMINAR_CAT_TIPO_CITA (payload):"+JSON.stringify(action.payload));
                        var idCatTipoCita={};
                        idCatTipoCita["id"]=action.payload.id;
                        dataSources[action.dataType].DeleteDataParams((data,mensaje)=>{ 
                            if(data===null){
                                console.log("FALLA PETICION A ELIMINAR_CAT_TIPO_CITA:"+mensaje);
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                                console.log("ELIMINAR_CAT_TIPO_CITA (data):"+JSON.stringify(data));
                                console.log("ELIMINAR_CAT_TIPO_CITA (data):"+data);
                                if(data===true){
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:ELIMINAR_CAT_TIPO_CITA,
                                        dataType:action.dataType,
                                        payload:action.payload.id});
                                        
                                    let count=parseInt( getState().estadosReducer.registros) - 1;
                                    console.log("coun:"+count); 
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
        
                                }
            
                            }
                        },idCatTipoCita);                                          
                     break; 
    

        default:
                next(action);

    }
}

}

export default createCatTipoCitaRestMiddleware;