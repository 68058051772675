import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO,  PACIENTE,CITA, CAT_TIPO_CITA,COLOR,ESQUEMA_TIPO_CITA, ESQUEMA_TIPO_CITA_HORARIO,PERIODO,HORARIO} from "../constants/action-type";
import { AGREGAR_ESQUEMA_TIPOCITA_HORARIO, CONSULTAR_ESQUEMA_TIPOCITA_HORARIOS } from "../constants/EsquemaTipocitaHorario-action-type";
import { AGREGAR_PERIODO } from "../constants/Periodo-action-type";
import { AGREGAR_HORARIO } from "../constants/Horario-action-type";

/* Funcion para loggin llamada logMiddleware */
const createEsquemaTipoCitaHorarioRestMiddleware =(esquemaTipoCitaHorarioURL,periodoURL,horarioURL,pacienteURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [ESQUEMA_TIPO_CITA_HORARIO]: new RestDataSource(esquemaTipoCitaHorarioURL),
        [PERIODO]: new RestDataSource(periodoURL),
        [HORARIO]: new RestDataSource(horarioURL),
        [PACIENTE]: new RestDataSource(pacienteURL)

    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de MedicoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** CITA INICIO */                                    
                case CONSULTAR_ESQUEMA_TIPOCITA_HORARIOS:
                    if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                    dataSources[action.dataType].GetData((dataEsquemaTipoCitaHorario,mensaje)=>{ 
                        if(dataEsquemaTipoCitaHorario===null){
                            if(action.loading){next({type:PETICION_WS_ERROR});}
                        }else{
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:CONSULTAR_ESQUEMA_TIPOCITA_HORARIOS,
                            dataType:action.dataType,
                            payload:dataEsquemaTipoCitaHorario}); 
                            if(action.loading){
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataEsquemaTipoCitaHorario.length});
                            }
                        }
                    });  
                            
                break;   
                            
                case AGREGAR_ESQUEMA_TIPOCITA_HORARIO:                           
                console.log("PAYLOAD (AGREGAR_ESQUEMA_TIPOCITA_HORARIO):::>><<"+JSON.stringify(action.payload));
                
                if(action.loading){next({type:PETICION_WS_SOLICITANDO});}
                dataSources[PERIODO].SetData((dataPeriodo,mensaje)=>{ 
                    if(dataPeriodo===null){
                        console.log("FAllo peticion a Servicio:"+mensaje);
                        next({type:PETICION_WS_ERROR});
                    }else{
                        console.log("Se actuliza en Axios (dataPeriodo):"+JSON.stringify(dataPeriodo));
    
                        next({     //por cada registro devuelto del Webservices se envia la funcion next
                            type:AGREGAR_PERIODO,
                            dataType:PERIODO,
                            payload:dataPeriodo});

                       /*** SE AGREGA HORARIO **/
                       action.payload.horario["PERIODO_id"]=dataPeriodo.id;

                       console.log("datosHorario"+JSON.stringify(action.payload.horario));

                        dataSources[HORARIO].SetData((dataHorario,mensaje)=>{ 
                            if(dataHorario===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataHorario):"+JSON.stringify(dataHorario));
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:AGREGAR_HORARIO,
                                    dataType:HORARIO,
                                    payload:dataHorario});
                            ///*** SE AGREGA ESQUEMA TIPO_CITA HORARIO 
                           // action.payload.esquemaTipoCitaHorario["HORARIO_id"]=dataHorario.id;

                            var datosEsquemaTipoCitaHorario ={};
                            datosEsquemaTipoCitaHorario["ESQUEMA_TIPO_CITA_id"]=action.payload.esquemaTipoCitaHorario.id;
                            datosEsquemaTipoCitaHorario["HORARIO_id"]=dataHorario.id;

                            console.log("datosEsquemaTipoCitaHorario"+JSON.stringify(datosEsquemaTipoCitaHorario));
                            dataSources[ESQUEMA_TIPO_CITA_HORARIO].SetData((dataEsquemaTipo_CitaHorario,mensaje)=>{ 
                                if(dataEsquemaTipo_CitaHorario===null){
                                    console.log("FAllo peticion a Servicio:"+mensaje);
                                    next({type:PETICION_WS_EXITOSA});
                                }else{
                                    console.log("Se actuliza en Axios (dataEsquemaTipo_CitaHorario):"+JSON.stringify(dataEsquemaTipo_CitaHorario));
                
                                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                                        type:AGREGAR_ESQUEMA_TIPOCITA_HORARIO,
                                        dataType:ESQUEMA_TIPO_CITA_HORARIO,
                                        payload:dataEsquemaTipo_CitaHorario});
                                // FIN 
                                      //  let count=parseInt( getState().estadosReducer.registros) + 1;

                                        next({type:PETICION_WS_EXITOSA});
                                     //   next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                        next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 

                                ///
                                    }
                                },datosEsquemaTipoCitaHorario);
                            ///
                                }
                            },action.payload.horario);
        
                        ///
                            }
                        },action.payload.periodo);
                break;   


        default:
                next(action);

    }
}

}

export default createEsquemaTipoCitaHorarioRestMiddleware;