import {USUARIO_ACTUAL,USUARIO_LOGOUT,SET_PROFIL} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state,action){

    console.log("Dentro de REDUCERS (AUTENTICACION):"+action.type);
    console.log("Dentro de REDUCERS (payload):"+JSON.stringify(action.payload));

    switch(action.type){
        case USUARIO_ACTUAL:
            return {
                ...state,
                autenticado:true,
                usuario:action.payload,
                profil:action.profil

            } 
        case USUARIO_LOGOUT:
            return {
                ...state,
                autenticado:false,
                usuario:{},
                profil:"publico"
            }     
                          
        default:
            return state||estadoInicial.autenticacion;
    }

}


