import {connect} from "react-redux";
import React,{Component} from "react";
import { CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

export default function SliderContainer(props){

    return(<Page citas={props.citas} 
                setCitaSala={props.setCitaSala} 
                citaSala={props.citaSala}
            />)
}
