import {RestDataSource} from "./RestDataSource";
import { MOSTRAR_MENSAJE, PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
         TIPO_DE_DATO, PROCEDIMIENTO} from "../constants/action-type";
import { CONSULTAR_PROCEDIMIENTOS,AGREGAR_PROCEDIMIENTO,ACTUALIZAR_PROCEDIMIENTO,ELIMINAR_PROCEDIMIENTO } from "../constants/Procedimiento-action-type";
import { PROFIL_PACIENTE } from "../../utils/constantes";


/* Funcion para loggin llamada logMiddleware */
const createProcedimientoRestMiddleware =(ProcedimientoURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [PROCEDIMIENTO]: new RestDataSource(ProcedimientoURL)
    }

return ({getState, dispatch}) => next => action => {
    console.log("Dentro de ProcedimientoRestMiddleware:"+action.type);

    /* Antes de enviar al Reducer */
    switch(action.type){

                /** PROCEDIMIENTO INICIO */                                    
                case CONSULTAR_PROCEDIMIENTOS:

                    let profil=getState().autenticacionReducer.profil;
                    if(profil===PROFIL_PACIENTE){ //SE REALIZA LA BUSQUEDA DE PRESION ARTERIAL POR PACIENTE
                        var datoProcedimientoByPaciente ={};
                        datoProcedimientoByPaciente['PACIENTE_id']=getState().PacienteUsuarioReducer.pacienteUsuario[0].PACIENTE_id;
                        console.log("CONSULTAR_PROCEDIMIENTOS(datoProcedimientoByPaciente):"+JSON.stringify(datoProcedimientoByPaciente));

                        if(action.loading){
                            next({type:PETICION_WS_SOLICITANDO});
                        }
                        dataSources[action.dataType].GetDataParams((dataProcedimiento,mensaje)=>{ 
                            if(dataProcedimiento===null){
                                if(action.loading){next({type:PETICION_WS_ERROR});}
                            }else{
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:CONSULTAR_PROCEDIMIENTOS,
                                dataType:action.dataType,
                                payload:dataProcedimiento}); 
                                if(action.loading){
                                    next({type:PETICION_WS_EXITOSA});
                                    next({type:TIPO_DE_DATO,dataType:action.dataType,registros:dataProcedimiento.length});
                                }
                            }
                        },datoProcedimientoByPaciente);    
                    }  

                  
                break;   

                case AGREGAR_PROCEDIMIENTO:
                    console.log("AGREGAR_PROCEDIMIENTO(Middleware):"+JSON.stringify(action.payload));

                    next({type:PETICION_WS_SOLICITANDO});
                                   
                    dataSources[action.dataType].SetData((dataProcedimiento,mensaje)=>{ 
                        if(dataProcedimiento===null){
                            console.log("FALLA PETICION A Procedimiento:"+mensaje);
                            next({type:PETICION_WS_ERROR});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se ha producido un ERROR al procesar su solicitud. ("+mensaje+")"}); 
                        }else{
                            console.log("AGREGAR_PROCEDIMIENTO (data):"+dataProcedimiento);
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:AGREGAR_PROCEDIMIENTO,
                                dataType:action.dataType,
                                payload:dataProcedimiento});
                                
                            let count=parseInt( getState().estadosReducer.registros) + 1;
                            console.log("coun:"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                        }
                    },action.payload);

                 break; 

                 case ACTUALIZAR_PROCEDIMIENTO:
                     next({type:PETICION_WS_SOLICITANDO});

                           dataSources[action.dataType].UpdateData((dataProcedimiento,mensaje)=>{ 
                            if(dataProcedimiento===null){
                                console.log("FAllo peticion a Servicio:"+mensaje);
                                next({type:PETICION_WS_EXITOSA});
                            }else{
                                console.log("Se actuliza en Axios (dataProcedimiento):"+dataProcedimiento);
            
                                next({     //por cada registro devuelto del Webservices se envia la funcion next
                                    type:ACTUALIZAR_PROCEDIMIENTO,
                                    dataType:action.dataType,
                                    payload:dataProcedimiento});
                                let count=parseInt( getState().estadosReducer.registros) + 0;
                                next({type:PETICION_WS_EXITOSA});
                                next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                                next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                                }
                            },action.payload);
                              
                 break;    

                 case ELIMINAR_PROCEDIMIENTO:
                   
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].DeleteData((dataProcedimiento,mensaje)=>{ 
                        if(dataProcedimiento===null){
                            console.log("FAllo peticion a Servicio:"+mensaje);
                            next({type:PETICION_WS_EXITOSA});
                        }else{
                            console.log("Se Elimina en Axios (dataProcedimiento):"+JSON.stringify(dataProcedimiento));
        
                            next({     //por cada registro devuelto del Webservices se envia la funcion next
                                type:ELIMINAR_PROCEDIMIENTO,
                                dataType:action.dataType,
                                payload:dataProcedimiento});

                            let count=parseInt( getState().estadosReducer.registros) - 1;
                            console.log("coun(eliminar):"+count); 
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:count});
                            next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                            }
                        },action.payload);
        
                    break;  



 

        default:
                next(action);

    }
}

}

export default createProcedimientoRestMiddleware;