import {RestDataSource} from "./RestDataSource";
import {INSTITUCION,PACIENTE,PRODUCTO,PROVEEDOR,CONSULTAR,
            MOSTRAR_MENSAJE,
            PETICION_WS_SOLICITANDO, PETICION_WS_EXITOSA, PETICION_WS_ERROR,
            TIPO_DE_DATO,
            MEDICO,
            PERSONA} from "../constants/action-type";
import {CONSULTAR_INSTITUCIONES, AGREGAR_INSTITUCION, ACTUALIZAR_INSTITUCION,ELIMINAR_INSTITUCION} from "../constants/Institucion-action-type";
import { PROFIL_ADMINISTRADOR } from "../../utils/constantes";



/* middleware pasa como parameto 2 Url para realizar la consulta antes de enviar la llamada a los reducers
y regresa una funcion
*/

export const createInstitucionMiddleware =(institucionesURL)=>{
    /* Obtiene datos de webservice  */
    const dataSources={
        [INSTITUCION]: new RestDataSource(institucionesURL),
    }

/* Realiza el dispacht dependiendo de la accion  */
    return ({dispath,getState})=>next=>action =>{

        console.log("Dentro de createRestMiddleware:"+action.type);

        /* Antes de enviar al Reducer */
        switch(action.type){           

        case CONSULTAR:
            let profil=getState().autenticacionReducer.profil;
            console.log("PERFIL DEL USUARIO:"+profil);
            if(profil===PROFIL_ADMINISTRADOR){//(29junio21) se agrega consulta para INSTITUCION
            }else{
                if(getState().datosReducer[action.dataType].length===0){
                    next({type:PETICION_WS_SOLICITANDO});
                    dataSources[action.dataType].GetData((data)=>{
                          //invoca la accion de consultar de reducer con (next)
                          data.forEach(item=>next({     //por cada registro devuelto del Webservices se envia la funcion next
                              type:CONSULTAR,
                              dataType:action.dataType,
                              payload:item})) ;   /* es el dato enviado */                                                   
                            next({type:PETICION_WS_EXITOSA});
                          }
                       );             
                  }
  
            }

        break;
              

       /** INSTITUCIONES INICIO */

                
        case CONSULTAR_INSTITUCIONES:
            let profil2=getState().autenticacionReducer.profil;
            console.log("PERFIL DEL USUARIO:"+profil2);
            if(profil2===PROFIL_ADMINISTRADOR){//(29junio21) se agrega consulta para INSTITUCION
            }else{
                if(action.loading){next({type:PETICION_WS_SOLICITANDO});}//Muestra loading      
                dataSources[action.dataType].GetData((data,mensaje)=>{ 
                    if(data===null){
                        if(action.loading){next({type:PETICION_WS_ERROR});}
                        
                    }else{
                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                        type:CONSULTAR_INSTITUCIONES,
                        dataType:action.dataType,
                        payload:data}); 
                        if(action.loading){
                            next({type:PETICION_WS_EXITOSA});
                            next({type:TIPO_DE_DATO,dataType:action.dataType,registros:data.length}); //muestra tipo y numero registros
                        }
                    }
                    }
                );             

            }
            break;   
                

        case AGREGAR_INSTITUCION:
            next({type:PETICION_WS_SOLICITANDO});
            dataSources[action.dataType].SetData((data,mensaje)=>{ 
                if(data===null){
                    console.log("FAllo peticion a Servicio:"+mensaje);
                    next({type:PETICION_WS_EXITOSA});
                }else{
                    console.log("AGregar instirucion (data):"+data);

                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                        type:AGREGAR_INSTITUCION,
                        dataType:action.dataType,
                        payload:data});
                    let count=parseInt( getState().estadosReducer.registros) + 1;
                    console.log("coun:"+count); 
                    next({type:PETICION_WS_EXITOSA,dataType:action.dataType,registros:count}); 
                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se agrego el registro exitosamente!."}); 
                    }
                },action.payload);

            break;     
            
        case ACTUALIZAR_INSTITUCION:
            next({type:PETICION_WS_SOLICITANDO});
            dataSources[action.dataType].UpdateData((data,mensaje)=>{ 
                if(data===null){
                    console.log("FAllo peticion a Servicio:"+mensaje);
                    next({type:PETICION_WS_EXITOSA});
                }else{
                    console.log("Se actuliza en Axios (data):"+data);

                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                        type:ACTUALIZAR_INSTITUCION,
                        dataType:action.dataType,
                        payload:data});
                    let count=parseInt( getState().estadosReducer.registros) + 0;
                    next({type:PETICION_WS_EXITOSA,dataType:action.dataType,registros:count}); 
                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se actualizo el registro exitosamente!."}); 
                    }
                },action.payload);

            break;      
            
        case ELIMINAR_INSTITUCION:
            next({type:PETICION_WS_SOLICITANDO});
            dataSources[action.dataType].DeleteData((data,mensaje)=>{ 
                if(data===null){
                    console.log("FAllo peticion a Servicio:"+mensaje);
                    next({type:PETICION_WS_EXITOSA});
                }else{
                    console.log("Se Elimina en Axios (data):"+JSON.stringify(data));

                    next({     //por cada registro devuelto del Webservices se envia la funcion next
                        type:ELIMINAR_INSTITUCION,
                        dataType:action.dataType,
                        payload:data});
                    let count=parseInt( getState().estadosReducer.registros) - 1;
                    next({type:PETICION_WS_EXITOSA,dataType:action.dataType,registros:count}); 
                    next({type:MOSTRAR_MENSAJE,abrirMensaje:true,mensaje:"Se elimino el registro exitosamente!."}); 
                    }
                },action.payload);

            break;             



            default:
                    next(action);

        }
        

    }

}