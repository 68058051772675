import React,{Component} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CardActionArea, Container } from "@material-ui/core";
import runImg from "../../../logo/1_opt.jpg";
import runImg2 from "../../../logo/1_opt.jpg";
import CardMedia from '@material-ui/core/CardMedia';



    function Page(props) {

    const useStyles  = () => makeStyles(theme => ({
        root: {
            maxWidth: 345,
            flexDirection:"column",
            borderRadius:"19px",
            position:"relative",
            display:"flex"

        },
        card: {
            maxWidth: 345,
            padding: theme.spacing.unit,
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing.unit
        },
        }));
    const classes = useStyles();    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let cardAncho="80%";
    if(fullScreen){
        cardAncho="100%"
    }
      let errorsForm=props.errorsForm;
      let loading=props.loading;

      console.log("fullScreen:"+fullScreen);

        return (<Container maxWidth="sm" className={classes.container}>
                <Card variant="outlined" style={{width:cardAncho}} className={classes.root} elevation={3}>
                 
                    <CardActionArea>
                
                    <CardMedia
                         style = {{ height: 0, paddingTop: '40%'}}
                            image= {runImg}
                            title="Bienvenido a la agenda medica de Bildsoft"
                        />
                       

                    
                    </CardActionArea>  

                    <CardHeader        
                                title="Accede a tu cuenta"
                                subheader="InnovaAgenda"
                     /> 
                    <form onSubmit={props.handleSubmit}>
                             
       
                        

                        {errorsForm && <Alert severity="error">{errorsForm}</Alert>}

                        <CardContent >
                        
                        {loading && <div className="container-fluid" align="center"><CircularProgress color="secondary" /> </div> }

                            <TextField
                                error={props.touched.usuario&&props.errors.usuario? true:false}
                                helperText={props.touched.usuario&&props.errors.usuario?props.errors.usuario:""}
                                autoFocus
                                margin="normal"
                                id="usuario"
                                label="Usuario:"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={props.handleChange} value={props.values.usuario}
                                onBlur={props.handleBlur}
                            />
                            <TextField
                                error={props.touched.password&&props.errors.password? true:false}
                                helperText={props.touched.password&&props.errors.password?props.errors.password:""}
                                margin="normal"
                                id="password"
                                label="Password:"
                                fullWidth
                                variant="outlined"
                                type="password"
                                onChange={props.handleChange} 
                                value={props.values.password}
                                onBlur={props.handleBlur}
                                autoComplete="off"

                            />
                            
                            </CardContent>
                            <CardActions>
                                <Button size="large" href="/registro" color="primary" variant="outlined">
                                        Registro
                                </Button>
                                <Button size="large" onClick={props.handleSubmit} variant="contained" color="primary" disabled={loading} type="submit">
                                    Ingresar
                                </Button>
                            </CardActions>
                            

                        </form>   
                    </Card>
                  </Container>  
                )
    
}
export default Page;
