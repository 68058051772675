import {CONSULTAR_PACIENTE_USUARIOS, AGREGAR_PACIENTE_USUARIO, ACTUALIZAR_PACIENTE_USUARIO,ELIMINAR_PACIENTE_USUARIO,CONSULTAR_PACIENTE_USUARIO_BY_ID,CONSULTAR_PACIENTE_USUARIOS_LOCAL_STORAGE} from "../constants/PacienteUsuario-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (PACIENTE_USUARIOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_PACIENTE_USUARIOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }
        case CONSULTAR_PACIENTE_USUARIO_BY_ID:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }            
        case CONSULTAR_PACIENTE_USUARIOS_LOCAL_STORAGE:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }            
            

        case AGREGAR_PACIENTE_USUARIO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_PACIENTE_USUARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_PACIENTE_USUARIO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER PACIENTE_USUARIOS");
            return state||estadoInicial.datos;
        

    }
}
