import {CONSULTAR,UPDATE,DELETE} from "../constants/action-type";
import {estadoInicial} from "../store/initialState";


export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (MODEL):"+action.type);

    switch(action.type){

        case CONSULTAR:
            console.log("Dentro de REDUCERS :"+state[action.dataType]+" - "+action.type+" - "+action.payload);

            return{
                ...state,
                [action.dataType]:state[action.dataType].concat([action.payload])
            }
        case UPDATE:
                return{
                    ...state,
                    [action.dataType]:state[action.dataType].map(p=>p.id===action.payload.id?action.payload:p)
                }
        case DELETE:
        return{
            ...state,
            [action.dataType]:state[action.dataType].filter(p=>p.id!==action.payload)
        }
        default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER");
            return state||estadoInicial.datos;

    }
}