import {CONSULTAR_PACIENTE_USUARIOS_LOCAL_STORAGE,CONSULTAR_PACIENTE_USUARIOS,AGREGAR_PACIENTE_USUARIO,ACTUALIZAR_PACIENTE_USUARIO, ELIMINAR_PACIENTE_USUARIO} from "../constants/PacienteUsuario-action-type";


/* PACIENTE_USUARIOS (HOSPITAL CLINICA) */
export const obtenerPacienteUsuarioListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_PACIENTE_USUARIOS,
        dataType:dataType,
        loading: true
    }
}

export const obtenerPacienteUsuarioListLocalStorageAction = (dataType,payload,loading)=> {
    return {
        type:CONSULTAR_PACIENTE_USUARIOS_LOCAL_STORAGE,
        dataType:dataType,
        payload: payload,
        loading: loading

    }
}



export const agregarPacienteUsuarioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PACIENTE_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarPacienteUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PACIENTE_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarPacienteUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PACIENTE_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
