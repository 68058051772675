import {CONSULTAR_MEDICO_USUARIOS_LOCAL_STORAGE,CONSULTAR_MEDICO_USUARIOS,AGREGAR_MEDICO_USUARIO,ACTUALIZAR_MEDICO_USUARIO, ELIMINAR_MEDICO_USUARIO} from "../constants/MedicoUsuario-action-type";


/* MEDICO_USUARIOS (HOSPITAL CLINICA) */
export const obtenerMedicoUsuarioListLocalStorageAction = (dataType,payload,loading)=> {
    return {
        type:CONSULTAR_MEDICO_USUARIOS_LOCAL_STORAGE,
        dataType:dataType,
        payload: payload,
        loading: loading

    }
}

/* MEDICO_USUARIOS (HOSPITAL CLINICA) */
export const obtenerMedicoUsuarioListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_MEDICO_USUARIOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarMedicoUsuarioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_MEDICO_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarMedicoUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_MEDICO_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarMedicoUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_MEDICO_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
