import {SOLICITANDO_PETICION_WS,EXITOSA_PETICION_WS,ERROR_PETICION_WS,STATE_START_EDITING,
        STATE_END_EDITING,INICIANDO_CRECION_PACIENTE, STATE_START_CREATING, INICIA_CREACION_PACIENTE,
            FINALIZA_CREACION_PACIENTE,
                AUTENTICACION_LOGIN,INICIA_LOGIN,FINALIZA_LOGIN,LOGIN_FALLO,LOGIN_EXITO} from "../constants/action-type"; 
import {estadoInicial} from "../store/initialState";

export default function(state,action){

    console.log("Dentro de REDUCERS (LOGIN):"+action.type);


    switch(action.type){
        case AUTENTICACION_LOGIN:
            return {
                ...state,
                usuario:action.payload
            } 

        case INICIA_LOGIN:
            return {
                ...state,
                loading:true
            } 

        case FINALIZA_LOGIN:
            return {
                ...state,
                loading:false
            }                

        case LOGIN_EXITO:
            return {
                ...state,
                loading:false,
                usuario:"diego"
            } 
                
        case LOGIN_FALLO:
            console.log("Dentro de case (LOGIN):"+action.type);
            console.log("Dentro de case (LOGIN):"+action.payload);

            return {
                ...state,
                loading:false,
                errors:action.payload
            } 
                
        default:
            return state||estadoInicial.login;
    }

}