import {CONSULTAR_INSTITUCIONES_LOCAL_STORAGE,CONSULTAR_INSTITUCIONES,AGREGAR_INSTITUCION,ACTUALIZAR_INSTITUCION, ELIMINAR_INSTITUCION} from "../constants/Institucion-action-type";


/* INSTITUCIONES (HOSPITAL CLINICA) */

export const obtenerInstitucionListLocalStoraAction = (dataType,payload)=> {
    return {
        type:CONSULTAR_INSTITUCIONES_LOCAL_STORAGE,
        dataType:dataType,
        loading: false,
        payload: payload
    }
}

export const obtenerInstitucionListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_INSTITUCIONES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarInstitucionWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_INSTITUCION,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarInstitucionWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_INSTITUCION,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarInstitucionWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_INSTITUCION,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}
