import {CONSULTAR_PROCEDIMIENTOS, AGREGAR_PROCEDIMIENTO, ACTUALIZAR_PROCEDIMIENTO,ELIMINAR_PROCEDIMIENTO} from "../constants/Procedimiento-action-type";
import {estadoInicial} from "../store/initialState";

export default function(state =estadoInicial.datos, action){

    console.log("Dentro de REDUCERS (PROCEDIMIENTOS):"+action.type);

    switch(action.type){
        
        case CONSULTAR_PROCEDIMIENTOS:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat.apply([],[action.payload])

            }

        case AGREGAR_PROCEDIMIENTO:
            return{
                ...state,
                [action.dataType]:state[action.dataType].concat(action.payload)

            }

        case ACTUALIZAR_PROCEDIMIENTO:
            return{
                ...state,
                [action.dataType]: state[action.dataType].map(p =>
                p.id === action.payload.id ? action.payload : p)        

            }
        case ELIMINAR_PROCEDIMIENTO:
            return{
                ...state,
                [action.dataType]: state[action.dataType]
                .filter(p => p.id !== action.payload.id)                    

            }

                default:
            console.log("ENTRA EN DEFAULT SWICTH  EN REDUCER PROCEDIMIENTOS");
            return state||estadoInicial.datos;
        

    }
}
