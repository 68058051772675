import Axios from "axios";

export class RestDataSource{

    constructor(base_url){
        this.BASE_URL=base_url;
    }

    
    GetDataParams(callback,params){

        console.log("Invocando GetDataParams ["+params);

        this.SendRequestParams("GET", this.BASE_URL,params,callback);
    }

    
    DeleteDataParams(callback,params){

        console.log("Invocando GetDataParams ["+params);

        this.SendRequestParams("DELETE", this.BASE_URL,params,callback);
    }

    GetData(callback){
            this.SendRequest("GET", this.BASE_URL,"",callback);
    }


    SetData(callback,datos){
        this.SendRequest("POST", this.BASE_URL,datos,callback);

    }

    SetDataFile(callback,datos){
        this.SendRequestFile("POST", this.BASE_URL,datos,callback);

    }
    
    UpdateData(callback,datos){
        this.SendRequest("PUT", this.BASE_URL,datos,callback);

    }
    
    DeleteData(callback,datos){
        this.SendRequest("DELETE", this.BASE_URL,datos,callback);

    }
    Login(datos){
        console.log("Invocando LOGIN");
        return this.SendRequestLogin("POST", this.BASE_URL,datos);

    }

    async SendRequest(method,url,datos,callback){
        try {
            console.log("Invocando SendRequest al servicion Rest:"+JSON.stringify(datos));

            let response=await Axios.request({
                headers: {
                    'Access-Control-Allow-Origin': true,
                  },
                timeout: 8000,
                method:method,
                url:url,
                responseType:"json",
                data:datos
            })
            .then(response =>{
                    console.log("RESPUSESTA EXITOSA :"+JSON.stringify(response)+" "+response.status+" "+JSON.stringify(response.data));
                    callback(response.data,response.status)})
            .catch(error => {
        
                    callback(null,error);});
        }catch (err) {
            callback(null,err);
            console.log("ERROR:"+err);
            console.error(err);
        }
    }

    async SendRequestFile(method,url,datos,callback){
        try {
            console.log("Invocando SendRequestFile al servicion Rest:"+JSON.stringify(datos));
            console.log("Invocando SendRequestFile al servicion Rest:"+datos["logotipo"]);


            const data = new FormData();
            data.append('file',datos["file"]);
            data.append('id',datos["id"]);
            console.log("Invocando SendRequestFile al servicion Rest:"+data);

            
            let response=await Axios.request({
                headers: {
                    'Access-Control-Allow-Origin': true,
                    'Content-Type': 'multipart/form-data' 
                  },
                timeout: 8000,
                method:method,
                url:url,
                responseType:"json",
                data:data
                
            })
            .then(response =>{
                    console.log("RESPUSESTA EXITOSA :"+JSON.stringify(response)+" "+response.status+" "+JSON.stringify(response.data));
                    callback(response.data,response.status)})
            .catch(error => {
        
                    callback(null,error);});
        }catch (err) {
            callback(null,err);
            console.log("ERROR:"+err);
            console.error(err);
        }
    }

    async SendRequestLogin(method,url,datos){
        console.log("Invocando [SendRequestLogin Rest] :"+JSON.stringify(datos));

        let response=await Axios.request({
            headers: {
                'Access-Control-Allow-Origin': true,
              },
            timeout: 8000,
            method:method,
            url:url,
            responseType:"json",
            data:datos,
        })
        return response;
    }
   
    async SendRequestParams(method,url,params,callback){
        try {
            console.log("Invocando [SendRequest params] :"+JSON.stringify(params));
            let response=await Axios.request({
                headers: {
                    'Access-Control-Allow-Origin': true,
                },
                timeout: 8000,
                method:method,
                url:url,
                responseType:"json",            
                params:params
            })
            .then(response =>{
                console.log("RESPUSESTA EXITOSA :"+response+" "+response.status+" "+JSON.stringify(response.data));
                callback(response.data,"exito")})
            .catch(error => {        
                        callback(null,error);});
        }catch (err) {
                callback(null,err);
                console.log("ERROR:"+err);
                console.error(err);
        }
        
    }

}